<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Edit Reimburse" :active="isActive" v-on:update:active="emitModalIsActive">

      <div :class="{'opacity-50 pointer-events-none': isLoadingGetInitData}">
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Nomor</label>
            <vs-input class="w-full" v-model="data.no_reimburse" placeholder="Generate Otomatis" disabled/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Tipe *</label>
            <vs-select autocomplete v-model="data.tipe" class="w-full" @input="onChangeTipe">
              <vs-select-item v-for="(it, index) in ['OPERASIONAL', 'HRGA']" :key="index" :value="it" :text="it"/>
            </vs-select>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Proyek</label>
            <vs-select autocomplete v-model="data.id_proyek" class="w-full">
              <vs-select-item v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="it.kode + ' - ' + it.nama"/>
            </vs-select>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Staf *</label>
            <div @click="modalAtasan.active = true">
              <vx-input-group>
                <vs-input :value="data.nama_staf" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Tanggal *</label>
            <flat-pickr class="w-full" v-model="data.tgl_reimburse"></flat-pickr>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Judul Reimburse *</label>
            <vs-input class="w-full" v-model="data.judul_reimburse"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Keterangan *</label>
            <vs-input class="w-full" v-model="data.keterangan"/>
          </div>
        </div>

        <vs-divider> Detail Transaksi </vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="flex flex-wrap justify-between items-center mb-1">
              <div class="mb-4 md:mb-0 mr-4">
                <div class="flex">
                  <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3 mr-2">Add</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <vs-table :data="data.details" stripe>
              <template slot="thead">
                <vs-th class="whitespace-no-wrap">Aksi</vs-th>
                <vs-th class="whitespace-no-wrap">Item Reimburse *</vs-th>
                <vs-th class="whitespace-no-wrap">No. Nota</vs-th>
                <vs-th class="whitespace-no-wrap">Tgl. Nota</vs-th>
                <vs-th class="whitespace-no-wrap">Bukti Nota</vs-th>
                <vs-th class="whitespace-no-wrap">Total *</vs-th>
                <vs-th class="whitespace-no-wrap">Keterangan *</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
                  <vs-td><vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/></vs-td>
                  <vs-td>
                    <div class="w-64">
                      <vx-input-group>
                        <vs-input placeholder="Item Reimburse" :value="item.nama_item_pengadaan" readonly @click="chooseItemPengadaan(item.uuid)"/>
                        <template slot="append">
                          <div class="append-text btn-addon">
                            <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="chooseItemPengadaan(item.uuid)"/>
                          </div>
                        </template>
                      </vx-input-group>
                    </div>
                  </vs-td>
                  <vs-td><vs-input class="w-48" type="text" v-model="item.no_nota"/></vs-td>
                  <vs-td><flat-pickr v-model="item.tgl_nota"></flat-pickr></vs-td>
                  <vs-td>
                    <div class="flex items-center space-x-2">
                      <vs-input class="w-64" type="file" accept=".jpeg,.jpg,.png,.pdf" multiple v-model="item.filesTmp" @change="item.files = $event.target.files"/>
                    </div>
                  </vs-td>
                  <vs-td><v-money class="w-48 text-right" type="text" v-model="item.jml_reimburse"/></vs-td>
                  <vs-td><vs-input class="w-48" type="text" v-model="item.keterangan"/></vs-td>
                </vs-tr>
                <!--footer-->
                <vs-tr v-if="data.length > 0">
                  <vs-td></vs-td>
                  <vs-td></vs-td>
                  <vs-td></vs-td>
                  <vs-td></vs-td>
                  <vs-td></vs-td>
                  <vs-td class="left"><v-money class="w-48 text-right" type="text" :value="grandTotal" disabled/></vs-td>
                  <vs-td></vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>

        <div class="vx-row mt-6">
          <div class="vx-col w-full">
            <div class="flex justify-end">
              <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
              <vs-button :disabled="isLoading" @click="save">
                <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
                <span v-if="!isLoading">Simpan</span>
              </vs-button>
            </div>
          </div>
        </div>
      </div>

      <!--modal staf-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Data Staf"
                :active="modalAtasan.active"
                v-on:update:active="modalAtasan.active = $event">
        <Staf :selectable="true"
              :externalFilter="filterStaf"
              @selected="onSelectedModalStaf"/>
      </vs-popup>

      <!--modal item pengadaan-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Item Reimburse"
                :active="modalItemPengadaan.active"
                v-on:update:active="modalItemPengadaan.active = $event">
        <ItemPengadaan :selectable="true"
                       :externalFilter="filterItemPengadaan"
                       @selected="onSelectedModalItemPengadaan"/>
      </vs-popup>

      <ImageViewer ref="viewer"/>

    </vs-popup>
  </div>
</template>

<script>
import ReimburseRepository from '@/repositories/procurement/reimburse-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import Staf from '@/views/pages/master/staf/Staf'
import ItemPengadaan from '@/views/pages/master/item-pengadaan/ItemPengadaan'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import ImageViewer from '@/views/components/image-viewer/ImageViewer'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import VMoney from '@/views/components/v-money/VMoney'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { convertToFormData } from '@/utilities/common/global-methods'

export default {
  name: 'ReimburseEdit',
  props: ['isActive', 'idReimburse'],
  components: {
    ItemPengadaan,
    ValidationErrors,
    Staf,
    flatPickr,
    ImageViewer,
    VMoney
  },
  mounted () {
    this.getAllProyek()
  },
  watch: {
    isActive (active) {
      if (active) {
        this.getInitData()
      }
    }
  },
  data () {
    return {
      isLoading: false,
      isLoadingGetInitData: false,
      isCompressingNota: false,
      errors: null,
      modalAtasan: {
        active: false
      },
      modalItemPengadaan: {
        rowUuid: null,
        active: false
      },
      data: {
        id_proyek: null,
        id_staf: null,
        details: []
      },
      deletedIds: [],
      proyeks: []
    }
  },
  computed: {
    grandTotal () {
      return _.sumBy(this.data.details, item => item.jml_reimburse)
    },
    filterItemPengadaan () {
      return {
        id_proyek: this.data.id_proyek,
        group2: this.data.tipe
      }
    },
    filterStaf () {
      return { id_proyek: this.data.id_proyek }
    }
  },
  methods: {
    showBuktiNota (item) {
      const image = item.bukti_nota_url
      this.showImages([image])
    },

    showImages (images) {
      this.$refs.viewer.show(images)
    },

    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getInitData () {
      this.isLoadingGetInitData = true

      ReimburseRepository.show(this.idReimburse)
        .then(response => {
          this.data = response.data.data
          this.data.details = _.map(this.data.details, item => {
            item.uuid = uuid()
            item.bukti_nota = null
            return item
          })
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoadingGetInitData = false
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = convertToFormData({ ...this.data, deletedIds: this.deletedIds })
      ReimburseRepository.update(params, this.data.id)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    chooseItemPengadaan (rowUuid) {
      this.modalItemPengadaan.rowUuid = rowUuid
      this.modalItemPengadaan.active = true
    },

    onSelectedModalStaf (item) {
      this.data.id_staf = item.id
      this.data.nama_staf = item.nama
      this.modalAtasan.active = false
    },

    onSelectedModalItemPengadaan (data) {
      const uuid = this.modalItemPengadaan.rowUuid
      const index = _.findIndex(this.data.details, item => item.uuid === uuid)
      this.data.details[index].id_coa_debet = data.id_coa_debet
      this.data.details[index].id_item_pengadaan = data.id
      this.data.details[index].nama_item_pengadaan = data.nama
      this.modalItemPengadaan.active = false
    },

    addRow () {
      const row = {
        uuid: uuid(),
        fileTmp: ''
      }
      this.data.details.push(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.data.details, item => item.uuid === uuid)
      const item = this.data.details[index]
      if (item.id) {
        this.deletedIds.push(item.id)
      }
      this.data.details.splice(index, 1)
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    onChangeTipe (tipe) {
      this.data.details = []
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      if (!isActive) {
        this.resetData()
      }
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
